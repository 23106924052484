/* eslint-disable no-console,no-undef, camelcase, no-constant-condition */
import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
// import Datetime from "react-datetime";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// import { DateIconContainer } from "../CreateEvent/styles";
import RadioButton from "../CustomRadioButton";
import Waiting from "../Waiting";
import {
  getOrientation,
  resetOrientation,
  convertDateInTimezone,
  checkImage,
  getPermissionStatus,
  fetchApi,
} from "../../utils/methods";
import { ImageUrl, imageErrorMessage, TimeZone } from "../../utils/constants";
import { eventsTierPoints, recurringTypes, /* eventsTierPoints */ } from "../../../mockData";
import { getAllCompanies } from "../../redux/actions/challengeActions";
import { getUserCompanyDetails } from "../../redux/actions/userProfileActions";
import { EditEventAction, getCompanyDepartment } from "../../redux/actions";
import timezones from "../../../timezones.json";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  UploadPhotoContainer,
  // RemoveImage,
  InputImage,
  // CustomTextArea,
  ButtonContainer,
  CustomRadioButton,
  StyledLabelV2,
  // StyledDateTime,
  Locationwrapper,
  Heading,
  // InputContainerV2,
  EditorContainer,
  // FlayerMain,
  SearchDropDown,
  RemoveImageV2,
  CircleNew,
  MUICalendarWrapper,
  DashIconV1,
} from "../CreateEvent/style";
import { Main, ToolTip } from "../AdminTeamCreation/styles";
import StepsHeading from "../CreateEvent/stepNames";
import { TitleContainer, FieldTitle } from "../AdminTeamCreation/styles";
// import { StyledSvg } from "../MainEvents/styles";
import Editor from "../CKEditor";
import Select from "react-select";
import { getCompanyLocation } from "../../redux/actions";
import HoverButton from "../common/HoverButton";
import CommonDropdown from "../common/CommonDropDown/CommonDropdown";
// import { ActivityDropdown } from "../CreateChallengeForm/styles";
import CommonButton from "../common/CommonButton/CommonButton";
import { withTranslation } from "react-i18next";
import { Arrows, CalendarIcon, DashIcon, NextArrows, SearchButtonIcon, UploadIcon, closeIcon } from "../../utils/icons";
import { Border } from "../Journeys/styles";
import CircularProgressBar from "../CreateEvent/circularProgressBar";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SearchFilter1 } from "../PeopleHomeV2/styles";
import { http } from "../../redux/http";
import { EditEventAPI, GetCompanyInfo, GetTeams } from "../../redux/constants/apiConstants";
import { FetchCoaches } from '../../redux/constants/apiConstants';
import { BASE_URL } from "../../utils/config";
import { NewActivityDropdownCommon } from '../common/CommonDropDown/style';
import { Dropdown } from "react-bootstrap";

dayjs.extend(utc)
dayjs.extend(timezone)

class EditEvent extends Component {
  constructor(props) {
    super();
    let updatedTiers = eventsTierPoints;
    props.role === "WELLNESS_CHAMPION" && updatedTiers.splice(3);
    this.state = {
      imgSrc: "",
      title: "",
      date: dayjs(),
      endDate: dayjs(),
      location: "",
      description: "",
      imageName: "",
      time: dayjs().format("hh:mm a"),
      endTime: dayjs().format("hh:mm a"),
      showDateTimeInput: "",
      selectedMenu: "Select an option",
      showPlaces: true,
      amigosToInvite: [],
      openDateTimePicker: "",
      eventLink: "",
      companies: [],
      com_location: [],
      eventPointValue: "",
      limitUser: "0",
      addLimitCheckbox: false,
      isSpouse: false,
      isDependent: false,
      isEmployee: false,
      selectedOption: 0,
      eventPointKey: "Tier 1: No points",
      emailOption: {
        launch_event_email: 0,
        event_reminder_email: 0,
        event_complete_email: 0,
      },
      eventPoints: updatedTiers,
      time_zone: TimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : TimeZone,
      companiesPermission: false,
      locationPermission: false,
      recurringEvent: recurringTypes[0],
      eventLimitValue: 0,
      step: 1,
      so: true,
      selectedCompanyValue: "",
      selectedCompanyCheckBox: 0,
      dropdown: true,
      showBack: false,
      buttonClickScrollTop: false,
      flayerName: "",
      flayerValue: "",
      progress: 0,
      selectFlayer: 1,
      menuIsOpen: false,
      selectedLocation: [],
      selectedCompany: [],
      editImage: "",
      usersToInvite:0,
      allTeams:[],
      filteredCompanies:[],
      searchTerm: '',
      teams:[],
      getCoachData: [],
      so3: false,
      coachName: '',
      coachId: null,
      companyInfo:null,
      showFrequency: true,
    };
  }

  eventsTierPoints=[
    {"key": "Tier 1: No points", "value": 0},
    {"key": "Tier 2: 25 points", "value": 25},
    {"key": "Tier 3: 50 points", "value": 50},
    {"key": "Tier 4: 100 points", "value": 100},
    { "key": "Tier 5: 150 points", "value": 150 },
    { "key": "Tier 6: 200 points", "value": 200 },
    { "key": "Tier 7: 250 points", "value": 250 },
    { "key": "Tier 8: 300 points", "value": 300 },
    { "key": "Tier 9: 350 points", "value": 350 },
    { "key": "Tier 10: 400 points", "value": 400 },
    { "key": "Tier 11: 450 points", "value": 450 },
    { "key": "Tier 12: 500 points", "value": 500 },
    { "key": "Tier 13: 550 points", "value": 550 },
    { "key": "Tier 14: 600 points", "value": 600 },
    { "key": "Tier 15: 650 points", "value": 650 },
    { "key": "Tier 16: 700 points", "value": 700 },
    { "key": "Tier 17: 750 points", "value": 750 },
    { "key": "Tier 18: 800 points", "value": 800 },
    { "key": "Tier 19: 850 points", "value": 850 },
    { "key": "Tier 20: 900 points", "value": 900 },
    { "key": "Tier 21: 950 points", "value": 950 },
    { "key": "Tier 22: 1000 points", "value": 1000 },
  ]

  componentDidMount() {
    const companiesPermission = getPermissionStatus(
      "Show companies in event",
      this.props.userPermissions
    );
    const locationPermission = getPermissionStatus(
      "Show locations in event",
      this.props.userPermissions
    );
    if (companiesPermission) {
      if (!this.props.allCompanies) {
        this.props.fetchAllCompanies();
      }
    } else {
      this.props.fetchCompanyForUser();
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id],
      });
    }
    document.addEventListener("mousedown", this.handleClick);

    const { history, eventDetail, allCompanies, getCompanyLocation } =
      this.props;
    const { pathname } = history.location;
    const arr = pathname.split("/");
    this.fetchCoachData();
    if (!eventDetail || _.isEmpty(eventDetail)) {
      history.push(`/events/${arr[arr.length - 2]}`);
    } else {
      let TierPointsIndex = this.state.eventPoints.findIndex(
        (data) => data.value == _.get(eventDetail, "event_points", 10)
      );
      const eventTimezone = eventDetail.time_zone
        ? eventDetail.time_zone: TimeZone === "Asia/Calcutta"? "Asia/Kolkata": TimeZone;
      this.setState({
        eventId: eventDetail.id,
        imgSrc: `${ImageUrl}/${eventDetail.image}`,
        editImage: eventDetail.image,
        title: eventDetail.title,
        date: dayjs.utc(eventDetail.start_date).tz(eventTimezone),
        endDate: dayjs.utc(eventDetail.end_date).tz(eventTimezone),
        location: eventDetail.event_location || "",
        description: eventDetail.body,
        imageName: eventDetail.image,
        time: moment
          .utc(eventDetail.start_date)
          .tz(eventTimezone)
          .format("hh:mm a"),
        endTime: moment
          .utc(eventDetail.end_date)
          .tz(eventTimezone)
          .format("hh:mm a"),
        eventLink: eventDetail.event_link || "",
        selectedOption: eventDetail.event_location ? 0 : 1,
        companies: eventDetail.event_companies
          .split(",")
          .map((value) => parseInt(value,10)),
        selectedCompany: eventDetail.event_companies
          .split(",")
          .map((value) => parseInt(value,10)),
        eventPointValue: TierPointsIndex === -1 ? 10 : eventDetail.event_points,
        apiChecker:
          convertDateInTimezone(eventDetail.start_date)._d < moment()._d &&
          convertDateInTimezone(eventDetail.end_date)._d >= moment()._d
            ? true
            : false,
        com_location: eventDetail.event_city_states
          ? eventDetail.event_city_states.split(",").map((value) => parseInt(value,10)): [],
        selectedLocation: eventDetail.event_city_states
          ? eventDetail.event_city_states.split(",").map((value) => parseInt(value,10))
          : [],
        isSpouse: eventDetail.spouse,
        isDependent: eventDetail.dependent,
        isEmployee: false || !eventDetail.is_spouse_or_dependent,
        eventPointKey:
          eventsTierPoints[TierPointsIndex === -1 ? 0 : TierPointsIndex].key,
        time_zone: eventTimezone,
        emailOption: {
          launch_event_email: eventDetail.launch_event_email,
          event_reminder_email: eventDetail.event_reminder_email,
          event_complete_email: eventDetail.event_complete_email,
        },
        recurringEvent: eventDetail.is_recurring_event
          ? eventDetail.recurring_type
          : recurringTypes[0],
        eventLimitValue: eventDetail.recurring_event_limit,
        flayerValue: eventDetail.event_flyer,
        flayerName:
          eventDetail && eventDetail.event_flyer && eventDetail.event_flyer.length > 0 ? "Flyer" : "",
        selectFlayer:eventDetail&&eventDetail.event_flyer &&eventDetail.event_flyer.length>0?0:1,
        selectedCompanyCheckBox:
          eventDetail && eventDetail.is_global_event ? 0 : 1,
        usersToInvite: eventDetail?.is_team_event ? 3 : eventDetail?.event_city_states ? 2 :eventDetail?.event_departments ? 1 : 0,
        teams:eventDetail?.event_teams ? eventDetail?.event_teams?.split(",").map((value) => parseInt(value,10)): [],
        presentTeams:eventDetail?.event_teams ? eventDetail?.event_teams?.split(",").map((value) => parseInt(value,10)): [],
        departments:eventDetail?.event_departments ? eventDetail?.event_departments?.split(",").map((value) => parseInt(value,10)): [],
        presentDepartments:eventDetail?.event_departments ? eventDetail?.event_departments.split(",").map((value) => parseInt(value,10)): [],
        showDateTimeInput: eventDetail.is_all_day_event ? "date" : "",
        coachId: eventDetail?.coach_id?eventDetail?.coach_id:null
        // teams:eventDetail.event_teams ? eventDetail.event_teams.split(",").map((value) => parseInt(value,10)): [],
      });
      if (eventDetail.user_limit) {
        this.setState({
          addLimitCheckbox: true,
          limitUser: eventDetail.user_limit,
        });
      }
    }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission,
    });

    if (allCompanies) {
      allCompanies &&
        allCompanies.map((company) => {
          if (company.id == eventDetail.event_companies) {
            this.setState({
              selectedCompanyValue: company.company_name,
            });
          }
        });
    }
    if (
      eventDetail &&
      eventDetail.is_global_event === 0 &&
      eventDetail.event_companies
    ) {
      getCompanyLocation(eventDetail.event_companies);
    }

    const{getCompanyDepartment}=this.props;
    

    if(eventDetail.is_team_event){
      this.getTeams(eventDetail.event_companies);

    }else if(eventDetail.event_departments){
      getCompanyDepartment(eventDetail.event_companies);

    }

    if(!eventDetail.is_global_event){
      this.getCompanyInfo(eventDetail.event_companies);
    }
  }

  getCompanyInfo = async(companyId)=>{
    let apiUrl = GetCompanyInfo + "/" + companyId;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({companyInfo: res?.data?.companyInfo?.[0]})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  changeLimitCheckBox = (value) => {
    this.setState({
      addLimitCheckbox: value,
    });
  };

  checkBase64(image) {
    if (image) {
      let arr = image.split("/");
      if (arr[0] !== "event-image") {
        return true;
      } else {
        return false;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { eventDetail, allCompanies } = this.props;
    this.state.flayerName &&
      this.state.progress < 100 &&
      setTimeout(this.progress, 1000);
    if (allCompanies && this.state.selectedCompanyValue.length === 0) {
      allCompanies &&
        allCompanies.map((company) => {
          if (company.id == eventDetail.event_companies) {
            this.setState({
              selectedCompanyValue: company.company_name,
            });
          }
        });
    }
    if (this.state.buttonClickScrollTop) {
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.setState({ buttonClickScrollTop: false });
      }, 2000);
    }

    if (
      !this.state.companiesPermission &&
      this.props.userCompany !== prevProps.userCompany
    ) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id],
      });
    }

    if(prevProps.allCompanies != this.state.filteredCompanies){
      this.setState({filteredCompanies:this.props.allCompanies})
    }

    const { getCoachData, coachName } = this.state;
    if( eventDetail?.coach_id && getCoachData.length>0 && !coachName){
      const coachData = getCoachData?.find((coach) => coach?.id == eventDetail?.coach_id);
      if(!coachName){
        this.setState({coachName:coachData?.name})
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  fetchCoachData = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + FetchCoaches;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if(data.status === 'success'){
              let coaches = data.data;
              coaches.unshift({id:null,name:"None"}); 
              this.setState({getCoachData: coaches });
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    this.setState({ searchTerm: value });

    const filtered = this.props.allCompanies.filter((company) =>
      company.company_name.toLowerCase().includes(value)
    );

    this.setState({ filteredCompanies: filtered });
  };

  progress = () => {
    this.setState({ progress: this.state.progress + 20 });
  };

  removePDF = () => {
    this.setState({
      flayerValue: "",
      flayerName: "",
      progress: 0,
    });
  };

  uploadPDFFile = (e) => {
    let file = e.target.files[0];
    if (file) {
      let fileArr = file.name.split(".");
      if (
        fileArr[fileArr.length - 1].toUpperCase() == "PNG" ||"PDF" ||"JPEG" || "JPG"
      ) {
        if (file?.size / 1000000 <= 10) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({
              flayerValue: reader.result,
              flayerName: file.name,
            });
          };
        } else {
          toast.error("Please select PDF file less than 10MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error("Please select PDF file");
        document.getElementById("event-upload-file").value = "";
      }
    } else {
      this.changeLoader(false);
      document.getElementById("event-upload-file").value = "";
    }
  };

  onChangeLocation = (id) => {
    const { com_location } = this.state;

    if (com_location.includes(id)) {
      let index = com_location.findIndex((item) => item === id);
      if (index > -1) {
        com_location.splice(index, 1);
      }
    } else {
      com_location.push(id);
    }
    this.setState({
      com_location: com_location,
    });
  };

  onChangeDepartment = (id) => {
    const { departments } = this.state;
    if (departments.includes(id)) {
      let index = departments.findIndex((item) => item === id);
      if (index > -1) {
        departments.splice(index, 1);
      }
    } else {
      departments.push(id);
    }
    this.setState({
      departments: departments,
    });
  };

  onChangeTeam = (id) => {
    const { teams } = this.state;
    if (teams.includes(id)) {
      let index = teams.findIndex((item) => item === id);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(id);
    }
    this.setState({
      teams: teams,
    });
  };

  onChangeTimeZone = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleClick = (e) => {
    if (this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDateTimePicker: "",
      });
    }
  };

  setDatePickerWrapper = (node) => {
    if (!this.state.apiChecker) {
      this.datePickerWrapper = node;
    }
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if (file?.size / 1000000 <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document
          .getElementById("event-upload-file")
          .value.split("\\");
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: array[array.length - 1],
            editImage: reader.result,
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage,
                editImage: baseImage,
              });
            });
          });
        };
      } else {
        toast.error("Please select image file less than 20MB");
        document.getElementById("event-upload-file").value = "";
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById("event-upload-file").value = "";
    }
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  removePhoto = () => {
    // document.getElementById('edit-event-file').value = '';
    this.setState({
      imgSrc: "",
      imageName: "",
      editImage: "",
    });
  };

  changeDateTimeView = (value) => {
    if (value === "date") {
      this.setState({
        date: dayjs(),
        endDate: dayjs().add(1, 'day'),
        showDateTimeInput: value,
        allDayEvent: 1,
        showFrequency: false,
      });
    } else {
      this.setState({
        date: dayjs(),
        endDate:  dayjs().add(1, 'day'),
        showDateTimeInput: "",
        allDayEvent: 0,
        showFrequency: true,
      });
    }
  };

  onSelect = (value) => {
    this.setState({
      selectedMenu: value,
    });
  };

  // onSelectPoints = (name, value, key) => {
  //   window.console.log("value", value);
  //   this.setState({
  //     [name]: value,
  //     eventPointKey: key,
  //   });
  // };

  onSelectPoints = (name, /*value, key*/) => {
    this.setState({
      [name]: name.value,
      eventPointKey: name.key,
      eventPointValue: name.value,
    });
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: moment(e._d).format("MM/DD/YYYY"),
        time: moment(e._d).format("hh:mm a"),
        endDate: moment(e._d).format("MM/DD/YYYY"),
        endTime: "11:59 pm",
        openDateTimePicker: "",
      });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: moment(e._d).format("MM/DD/YYYY"),
      });
    } else {
      this.setState({
        [stateName]: moment(e._d).format("hh:mm a"),
        openDateTimePicker: stateName,
      });
    }
  };

  onChangeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: dayjs(e),
        time: dayjs(e).format("hh:mm a"),
        endDate: dayjs(e).add(2, 'day'),
        endTime: "11:59 pm",
        openDateTimePicker: "",
      });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: dayjs(e),
      });
    } else {
      this.setState({
        [stateName]: e,
        openDateTimePicker: stateName,
      });
    }
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: "",
      });
    } else {
      this.setState({
        openDateTimePicker: value,
      });
    }
  };

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies,
    });
  };
  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if (this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies,
    });
  };

  onLocationChange = (e) => {
    const { getPlacesAction } = this.props;
    this.setState(
      {
        location: e.target.value,
        showPlaces: true,
      },
      () => {
        if (this.state.location.length > 3 && this.state.location.trim() != "")
          getPlacesAction(this.state.location.trim());
      }
    );
  };

  setLocation = (location) => {
    this.setState({
      location: location,
      showPlaces: false,
    });
  };

  onSelectCategory = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  renderPlaces = (places) =>
    places.map((place, index) => (
      <div key={index} onClick={() => this.setLocation(place.description)}>
        <span>{place.description}</span>
      </div>
    ));

  saveEvent = () => {
    // e.preventDefault();
    const {
      apiChecker,
      com_location,
      eventId,
      title,
      date,
      description,
      // time,
      endDate,
      // endTime,
      location,
      eventLink,
      companies,
      eventPointValue,
      addLimitCheckbox,
      limitUser,
      selectedOption,
      time_zone,
      emailOption,
      locationPermission,
      recurringEvent,
      eventLimitValue,
      flayerValue,
      editImage,
      usersToInvite,
      departments,
      teams,
      coachId,
      selectFlayer
    } = this.state;
    const { /*EditEventAction,*/ companyDetails } = this.props;
    let isRecurringEvent = recurringEvent === recurringTypes[0] ? 0 : 1;
    const EventDetails = {
      title,
      companies: companies,
      body: description,
      image: editImage,
      end_date:dayjs(endDate).format("YYYY-MM-DD") + " " + dayjs(endDate, "hh:mm a").format("HH:mm:ss"),
      start_date:dayjs(date).format("YYYY-MM-DD") + " " + dayjs(date, "hh:mm a").format("HH:mm:ss"),
      event_location: !selectedOption ? location : null,
      event_link: selectedOption ? eventLink : null,
      id: eventId,
      event_points: eventPointValue,
      imageUpdated: this.checkBase64(editImage) ? 1 : 0,
      time_zone: time_zone,
      ...emailOption,
      is_recurring_event: isRecurringEvent,
      recurring_type: isRecurringEvent ? recurringEvent : null,
      recurring_event_limit: isRecurringEvent ? eventLimitValue : 0,
      event_flyer: selectFlayer==0? flayerValue:null,
      company_city_states: com_location,
      coach:coachId,
      event_for_spouse: this.state.isSpouse ? 1 : 0,
      event_for_dependent:this.state.isDependent ? 1 : 0
    };

    if (locationPermission) {
      // EventDetails.company_city_states = com_location;
      EventDetails.event_for_spouse = this.state.isSpouse;
      EventDetails.event_for_dependent = this.state.isDependent;
      if (this.props.locationDetails && this.props.locationDetails.length > 0) {
        if((com_location && com_location.length > 0)){
          (EventDetails.is_for_employee = 1)
        }else{
          (EventDetails.is_for_employee = 0)
        }
      } else {
        if (companyDetails["spouse_or_dependent"]) {
          EventDetails.is_for_employee = this.state.isEmployee ? 1 : 0;
        } else {
          EventDetails.is_for_employee = 1;
        }
      }
    }

    if (apiChecker) {
      EventDetails.is_event_started_or_over = 1;
    }
    if (addLimitCheckbox) {
      EventDetails.user_limit = limitUser;
    }

    if(usersToInvite === 0){
      EventDetails.company_departments = null;
      EventDetails.company_city_states = null;
      EventDetails.teams = null;
      EventDetails.is_for_employee = 1;
    }else if(usersToInvite === 1){
      EventDetails.company_departments = departments;
      EventDetails.company_city_states = null;
      EventDetails.teams = null;
      EventDetails.is_for_employee = 0;
    }else if(usersToInvite === 2){
      EventDetails.company_departments = null;
      EventDetails.company_city_states = com_location;
      EventDetails.teams = null;
      EventDetails.is_for_employee = 0;
    }else if(usersToInvite === 3){
      EventDetails.teams = teams;
      EventDetails.is_team_event=1;
      EventDetails.company_departments = null;
      EventDetails.company_city_states = null;
      EventDetails.is_for_employee = 0;
    }
    this.PostEvent(EventDetails);
  };

  PostEvent = async(payload)=>{
    
    try{

      const res = await fetchApi(EditEventAPI, "PUT",payload);

      if(res?.data?.message){
        toast.error(res.data.message);
        this.setState({
          buttonStatus: false,
        });
      }else{
        toast.success(res.data[0]);
        this.props.history.push(`/company/events/${this.state.eventId}`);
      }

    }catch(error){
      toast.error(error)

    }
  }

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  toggleRadioButton = (e) => {
    this.setState({ selectedOption: e });
    if (e === 1) {
      this.setState({ location: "" });
    } else {
      this.setState({ eventLink: "" });
    }
  };
  inviteToggleButton = (e) => {
    this.setState({
      usersToInvite: e,
      // companies: [],
      // selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        // city_location: [],
      });
    }
  };

  launchToggleButton = (e) => {
    this.setState({
      launch: e,
      // companies: [],
      // selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        // city_location: [],
      });
    }
  };
  

  wellnessCategories = [
    {
      name: "Nutrition Challenge",
      value: "nutrition",
    },
    {
      name: "Fitness Challenge",
      value: "fitness",
    },
    {
      name: "Workplace Challenge",
      value: "workplace",
    },
    {
      name: "Stress Challenge",
      value: "stress",
    },
    {
      name: "Health Challenge",
      value: "health",
    },
    {
      name: "Lifestyle Challenge",
      value: "lifestyle",
    },
  ];

  onSelectLocation = (name, value, id) => {
    this.setState({
      [name]: value,
    });
    this.props.getCompanyLocation(id);
    this.props.getCompanyDepartment(name.id);
    this.getTeams(name.id);
    this.getCompanyInfo(name.id);
  };

  getTeams = async(companyId)=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetTeams}/${companyId}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res
        })
      }
    }catch(error){
      toast.error(error.message);
    }
  }

  eventButtonStatusHover = () => {
    this.setState({
      showBack: true,
    });
  };

  eventButtonStatus = () => {
    this.setState({
      showBack: false,
    });
  };

  removeHtmlTags = (str) => str.replace(/<[^>]*>/g, "");

  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#9C9C9C"
        />
      </g>
    </svg>
  );

  redirect = () => {
    this.props.history.goBack();
    // this.props.history.push(`/events/${this.props.eventDetail.id}`);
  };

  renderHeading = () => (
    <ChallengeHeading style={{color:"#005c87"}}>
      <ToolTip
        margin={"auto 15px auto 0"}
        activeValue={"transparent"}
        height="40px"
        width="55px"
      >
        <HoverButton
          title="Close"
          width="24px"
          height="24px"
          svgPath={this.close()}
          onClick={this.redirect}
        />
      </ToolTip>
      Edit Event
    </ChallengeHeading>
  );

  stepOneForm = () => {
    const {
      title,
      description,
      step,
    } = this.state;

    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step" >{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Event Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel margin={"20px"} color={"#005c87"}>{this.props.t("Title of the event")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={"Type title here..."}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    color="#005c87"
                    border={"1px solid #afcbd3"}
                    isPadding={" 0px 20px 0px 12px"}
                  />
                </InputContainer>

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>

                <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("Event image")}{<span>*</span>}</StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <RemoveImageV2 >
                        <img alt="profilre-img" src={this.state.imgSrc}></img>
                        <div className="main-div" onClick={this.removePhoto}>
                          <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                        </div>
                      </RemoveImageV2>
                    ) : (
                      <div className="uploadImage">
                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1}
                          style={{background:"none"}}
                        >
                          <div className="middle-section">
                            <div>{UploadIcon()}</div>
                            <div className="title">{this.props.t("Upload File")}</div>
                            <div className="sub-title">{this.props.t("(.jpeg, .png)")}</div>
                            
                          </div>
                          {/* <i className="addImg">{this.props.t("Upload Image")}</i> */}
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                    {/* {this.state.imgSrc.length === 0 ? null : (
                      <RemoveImage onClick={this.removePhoto} style={{backgroundColor:"#F4AAA9"}}>
                        {this.props.t("Remove Image")}
                      </RemoveImage>
                    )} */}
                  </div>
                </UploadPhotoContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("About the event")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  DropdownIndicator = () => (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: '15px' }}
    >
      <path
        d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
        fill="#007AB1"
      />
    </svg>
    
  );

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  onDropDownSelectCoach = (name, id, value) => {
    this.setState({
      [name]: id,
      coachName: value
    });
  };

  stepTwoForm = () => {
    const {
      step,
      // apiChecker,
      location,
      eventLink,
      addLimitCheckbox,
      limitUser,
      selectedOption,
      eventPointValue,
      eventPoints,
      selectFlayer,
      flayerName,
      coachId,
      coachName,
      getCoachData
      // progress,
    } = this.state;

    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">
                  {this.props.t("How would you like to customize your event?")}
                </div>
              </div>
              <div className="formBody">
                <StyledLabel margin={"20px"} color={"#005c87"}>{this.props.t("Type of event")}?{<span>*</span>}</StyledLabel>
                <div className="radioButtonDiv">
                  <span 
                    // onClick={() => this.toggleRadioButton(0)}
                  >
                    <RadioButton
                      id="1"
                      // handler={this.toggleRadioButton}
                      value={0}
                      isChecked={selectedOption === 0}
                      label={this.props.t("Onsite Event")}
                      challengeCreate={true}
                      disabled
                    />
                  </span>
                  <span 
                    // onClick={() => this.toggleRadioButton(1)}
                  >
                    <RadioButton
                      id="2"
                      // handler={this.toggleRadioButton}
                      value={1}
                      isChecked={selectedOption === 1}
                      label={this.props.t("Virtual Event")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                      disabled
                    />
                  </span>
                </div>
                {selectedOption === 0 ? (
                  <StyledInputV2
                    placeholder={""}
                    name="location"
                    pColor={"rgba(0, 92, 135, 0.30))"}
                    value={location}
                    onChange={this.onLocationChange}
                    locationInput
                    border={"1px solid #afcbd3"}
                    isPadding={" 0px 20px 0px 12px"}
                  />
                ) : (
                  <StyledInputV2
                    placeholder={""}
                    name="eventLink"
                    pColor={"rgba(0, 92, 135, 0.30))"}
                    onChange={this.onChangeInput}
                    value={eventLink}
                    border={"1px solid #afcbd3"}
                    isPadding={" 0px 20px 0px 12px"}
                  />
                )}
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabelV2>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Event limit users")}{<span>*</span>}
                    </div>
                    <div className="radio" style={{color:"#005c87"}}>
                      <CustomRadioButton
                        // onClick={() => this.changeLimitCheckBox(false)}
                        style={{color:"#005c87",border:"1px solid #669db7"}}
                        background={"#85c0ea"}
                        disabled={true}
                      >
                        {" "}
                        {!addLimitCheckbox && <div></div>}
                      </CustomRadioButton>
                      {this.props.t("No Limit")}
                      <CustomRadioButton
                        // onClick={() => this.changeLimitCheckBox(true)}
                        style={{color:"#005c87",border:"1px solid #669db7"}}
                        background={"#85c0ea"}
                        marginL="24px"
                        disabled={true}
                      >
                        {" "}
                        {addLimitCheckbox && <div></div>}
                      </CustomRadioButton>
                      {this.props.t("Add Limit")}
                    </div>
                  </StyledLabelV2>
                  <StyledInputV2
                    placeholder={this.props.t("Enter limit here...")}
                    type={addLimitCheckbox ? "number" : "text"}
                    name="limitUser"
                    onChange={this.onChangeInput}
                    value={addLimitCheckbox ? limitUser : "All users"}
                    min="1"
                    pattern="[0-9]*"
                    disabled={true}
                    pColor={"rgba(0, 92, 135, 0.30))"}
                    // disabled={!addLimitCheckbox}
                    border={"1px solid #afcbd3"}
                    isPadding={" 0px 20px 0px 12px"}
                  />
                </InputContainer>

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px',display:"flex"}}/>

                <CommonDropdown
                  tileContainerStyle={{ width: "100%", padding:"0px 0px 20px 0px" }}
                  dropdownStyle={{ top: "95px" }}
                  labelText={"Event point value"}
                  isRequired={true}
                  dropdownOpen={this.state.so}
                  placeholder={this.props.t("Select Option")}
                  title={
                    eventPointValue == 0 ? "No points" : eventPointValue
                  }
                  id="dropdown-recurring"
                  onClick={() => this.setState({ so: !this.state.so })}
                  data={eventPoints}
                  onSelect={this.onSelectPoints}
                  active={eventPointValue}
                  onSelectParameter={["eventPointValue", "value", "key"]}
                  valueString={"Points"}
                  itemValue={true}
                  valueIndex={1}
                  labelMargin={"0px"}
                />
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>
                {
                  <TitleContainer padding="20px 0px 20px 0px">
                    <StyledLabel margin={"0px"}>{this.props.t("Event host")}</StyledLabel>
                    <NewActivityDropdownCommon
                      onClick={() => this.setState({ so3: !this.state.so3 })}
                    >
                      <Dropdown.Toggle>
                        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}><div style={{color: !coachName&& "rgba(0, 92, 135, 0.30)" }}>{getCoachData?.length==0?"None": coachName?coachName:"Select Coach"}</div>
                          <div>
                            {getCoachData?.length>0 &&<img
                              alt="image"
                              src={"/public/images/teamDownArrow.svg"}
                            />}
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      {getCoachData?.length>0 &&<Dropdown.Menu>
                        {getCoachData?.length>0 && getCoachData.map((item, index) => (
                          <Dropdown.Item
                            eventKey={index}
                            key={index}
                            onClick={() =>
                              this.onDropDownSelectCoach("coachId", item.id, item.name)
                            }
                            active={coachId === item.id}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>}
                    </NewActivityDropdownCommon>
                  </TitleContainer>}
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>
                <StyledLabel margin={"20px"} color={"#005c87"}>
                  {this.props.t("Would you like to add promotional document?")}{<span>*</span>}
                </StyledLabel>
                <div className="radioButtonDiv">
                  <span onClick={() => this.toggleFlayer(0)}>
                    <RadioButton
                      id="1"
                      handler={this.toggleFlayer}
                      value={0}
                      isChecked={selectFlayer === 0}
                      label={this.props.t("Yes")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>
                  <span onClick={() => this.toggleFlayer(1)}>
                    <RadioButton
                      id="2"
                      handler={this.toggleFlayer}
                      value={1}
                      isChecked={selectFlayer === 1}
                      label={this.props.t("No")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>
                </div>

                {selectFlayer === 0 && ( <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    <div className="uploadImage">
 
                      <InputImage
                        className="edit-profile-pic "
                        imageReload={1} 
                        style={{background:"unset",color:"#005c87",width:"100%", display:"flex",alignItems:"center",justifyContent:"center"}}
                      >
                        <div className="middle-section" style={{textAlign:"center"}}>
                          {flayerName
                            ? (<React.Fragment>   
                              <CircleNew width="70px" height="70px">
                                <CircularProgressBar
                                  strokeWidth="7"
                                  sqSize="64"
                                  percentage={100}
                                  color="#76AB78"
                                  hideText={true}
                                  // textValue="Culture Score"
                                />
                              </CircleNew>                           
                              <span className="flyer-name">{flayerName}</span></React.Fragment>)
                            : (<React.Fragment>                              
                              <div>{UploadIcon()}</div>
                              <div className="title">{this.props.t("Upload File")}</div>
                              <div className="sub-title">{this.props.t("(.pdf, .jpeg, .png)")}</div></React.Fragment>)}
 
                              
                        </div>
                        <input
                          id="event-upload-file"
                          type="file"
                          name="user"
                          accept=".jpeg, .png, .jpg, .pdf"
                          multiple={false}
                          onChange={(e) => this.uploadPDFFile(e)}
                          onClick={(e) =>
                            e.target.files[0] && this.uploadPDFFile(e)
                          }
                        />
                      </InputImage>
                      {flayerName && ( 
                        <RemoveImageV2 >
                          <div className="main-div" onClick={flayerName ? () => this.removePDF() : ""}>
                            <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                          </div>
                        </RemoveImageV2>)}
                    </div>
 
                  </div>
                </UploadPhotoContainer>)}

                {/* {selectFlayer === 0 ? (
                  <FlayerMain active={flayerName} width={progress>100?100:progress} background="white" border="1px solid #afcbd3" buttonBgColor="#005c87" pBarBgColor="#005c87" color="#005c87">
                    <div className="uploadImage">
                      <img
                        alt="profilre-img"
                        src={"/public/images/UploadIcon.png"}
                      ></img>
                    </div>
                    <div className="fileMain">
                      <div className="fileName">
                        {flayerName
                          ? flayerName
                          : "Select a .pdf, .jpg and .png file to upload"}
                      </div>
                      {flayerName && (
                        <div className="progressBar">
                          <div className="progressValue"></div>
                        </div>
                      )}
                    </div>
                    {flayerName ? (
                      <div
                        className="button"
                        onClick={flayerName ? () => this.removePDF() : ""}
                      >
                        {flayerName ? this.props.t("Remove") : this.props.t("Upload")}
                      </div>
                    ) : (
                      <div className="button">
                        {flayerName ? this.props.t("Remove") : this.props.t("Upload")}
                        <input
                          id="event-upload-file"
                          type="file"
                          name="user"
                          accept=".jpeg, .png, .jpg, .pdf"
                          multiple={false}
                          onChange={(e) => this.uploadPDFFile(e)}
                          onClick={(e) =>
                            e.target.files[0] && this.uploadPDFFile(e)
                          }
                        />
                      </div>
                    )}
                  </FlayerMain>
                ) : null} */}

              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  noOperation = () => {};

  removeCommaFromFirstPlace = (str) => {
    if (str.charAt(0) === ",") {
      return str.substring(1);
    }
    return str;
  };

  companyToggleButton = (e) => {
    this.setState({
      selectedCompanyCheckBox: e,
      companies: [],
      selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        com_location: [],
      });
    }
  };

  selectSpouseDependent = (stateName) => {
    this.setState((prev) => ({
      [stateName]: !prev[stateName],
    }));
  };

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { com_location, selectedLocation } = this.state;
    if (com_location.length == locationDetails?.length) {
      this.setState({
        com_location: selectedLocation,
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        com_location: arr,
      });
    }
  };

  selectAllTeam = (e, teamDetails)=>{
    e.preventDefault();
    const { teams, presentTeams } = this.state;
    if (teams.length == teamDetails.length) {
      this.setState({
        teams: presentTeams,
      });
    } else {
      let arr = [];
      for (let i = 0; i < teamDetails.length; i++) {
        arr.push(teamDetails[i].id);
      }
      this.setState({
        teams: arr,
      });
    }

  }


  selectAllDepartment = (e, locationDetails) => {
    e.preventDefault();
    const { departments, presentDepartments } = this.state;
    if (departments.length == locationDetails?.length) {
      this.setState({
        departments: presentDepartments,
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        departments: arr,
      });
    }
  };

  stepThreeForm = () => {
    const {
      companies,
      com_location,
      companiesPermission,
      step,
      // selectLocation,
      selectedCompanyValue,
      selectedCompanyCheckBox,
      selectedLocation,
      selectedCompany,
      isEmployee,
      isDependent,
      isSpouse,
      filteredCompanies,
      usersToInvite,
      allTeams,
      teams,
      departments,
      presentTeams,
      presentDepartments,
      companyInfo
    } = this.state;
    const { /*allCompanies,*/ locationDetails, role, eventDetail, /*companyDetails,*/t, departmentDetails } = this.props;
    
    window.console.log("eventDetail---------",eventDetail)
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Who would you like to invite?")}</div>
              </div>
              <div className="formBody">
                {companiesPermission && (
                  <StyledLabel margin={"20px"} color="#005c87">
                    {this.props.t("Host your event for?")}{<span>*</span>}
                  </StyledLabel>
                )}
                {companiesPermission && (
                  <div className="radioButtonDiv">
                    <span>
                      <RadioButton
                        id="1"
                        // handler={this.companyToggleButton}
                        value={0}
                        isChecked={selectedCompanyCheckBox === 0}
                        label={this.props.t("Global")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                        disabled
                      />
                    </span>
                    <span>
                      <RadioButton
                        id="2"
                        // handler={this.companyToggleButton}
                        value={1}
                        isChecked={selectedCompanyCheckBox === 1}
                        label={this.props.t("For an Individual Company")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                        disabled
                      />
                    </span>
                  </div>
                )}
                {role === "ADMIN" && (<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>)}
                {selectedCompanyCheckBox === 0 && companiesPermission && (
                  <Heading>
                    <div className="name" style={{color:"#005c87"}}>{this.props.t("Select Company To Invite")}</div>
                    {/* <div className="selectAllRadio">
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          style={{color:"#005c87",border:"1px solid #669db7"}}
                          background={"#85c0ea"}
                        >
                          {" "}
                          {allCompanies &&
                            companies.length === allCompanies.length && (<div></div>)}{" "}
                        </CustomRadioButton>{" "}
                        {allCompanies &&
                        companies.length === allCompanies.length
                          ? this.props.t("Deselect All")
                          : this.props.t("Select All")}
                      </span>
                    </div> */}

                    <SearchFilter1 style={{width:"416px",maxWidth:"416px"}}>
                      <StyledInputV2
                        type="text" placeholder={t("Search company")}
                        value={this.state.searchTerm} 
                        onChange={this.handleSearch} 
                        // onKeyDown={this.handleKeyDown}
                        style={{border:"none"}}
                        bordercolor="white"
                        pColor={"rgba(0, 92, 135, 0.30))"}
                        isPadding={" 0px 20px 0px 12px"}
                      />
                      <div className="search-icon"> {SearchButtonIcon()}</div>
                    </SearchFilter1>
                  </Heading>
                )}
                {selectedCompanyCheckBox === 0 && companiesPermission ? (
                  <Locationwrapper  background="white" isFlex={'1 1 50%'}>
                    {filteredCompanies && filteredCompanies.length > 0
                      ? filteredCompanies.map((company, index) => (
                        <div className="checkBoxWidth" key={index}>
                          <span style={{color:"#005c87"}}>
                            <CustomRadioButton
                              onClick={selectedCompany.includes(company.id) &&companies.includes(company.id)
                                ? null: () =>this.onCheckboxChange(company.id,company.company_name)}
                              background="#afcbd3"
                              
                            >
                              {" "}
                              {selectedCompany.includes(company.id) &&
                              companies.includes(company.id) ? (
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                ) : companies.includes(company.id) ? (
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                ) : null}{" "}
                            </CustomRadioButton>
                            {company.company_name}
                          </span>
                        </div>
                      ))
                      : null}
                  </Locationwrapper>
                ) : (
                  companiesPermission && (
                    <React.Fragment>
                      {/* <TitleContainer fullWidth display="none">
                        <FieldTitle style={{color:"#005c87"}}>
                          {this.props.t("Select Company")}
                          {<span>*</span>}
                        </FieldTitle>
                        <ActivityDropdown
                          placeholder={this.props.t("Select Option")}
                          title={
                            selectedCompanyValue
                              ? selectedCompanyValue
                              : this.props.t("Select Company")
                          }
                          id="dropdown-recurring"
                          isEditEvent="1"
                        >
                        </ActivityDropdown>
                      </TitleContainer> */}
                      <Heading>
                        <div className="name" style={{color:"#005c87"}}>{this.props.t(`Select a company to invite`)} <span> &nbsp;{companies ? "(01 Company Selected)" : ""} </span></div>
                        <SearchFilter1 style={{width:"416px",maxWidth:"416px"}}>
                          <StyledInputV2
                            type="text" placeholder={t("Search company")}
                            value={this.state.searchTerm} 
                            onChange={this.handleSearch} 
                            // onKeyDown={this.handleKeyDown}
                            bordercolor="white"
                            style={{border:"none"}}
                            pColor={"rgba(0, 92, 135, 0.30))"}
                            isPadding={" 0px 20px 0px 12px"}
                          />
                          <div className="search-icon"> {SearchButtonIcon()}</div>
                        </SearchFilter1>
                      </Heading>
   
                      <Locationwrapper background="white" isFlex={'1 1 50%'}>
                        {filteredCompanies && filteredCompanies.length > 0
                          ? filteredCompanies.map((company, index) => (
                            <div className="checkBoxWidth" key={index}>
                              <span style={{color:"#005c87"}} 
                                // onClick={()=>this.onSelectLocation(company)}
                              >
                                <RadioButton
                                  handler={()=>this.onSelectLocation(company)}
                                  id={company.id}
                                  value={companies}
                                  isChecked={companies.includes(company.id)}
                                  label={t(company.company_name)}
                                  style={{color:"#005c87"}}
                                  challengeCreate={true}
                                  disabled
                                />
                              </span>
                            </div>
                          )): null}
                      </Locationwrapper>
   
                    </React.Fragment>
                  )
                )}
                {role === "ADMIN" && (<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>)}
                 
                <div className="name" style={{color:"#005c87",marginTop:"20px",marginBottom:"16px"}} >
                  {this.props.t("Select users")}
                  <span style={{color:" #F4AAA9"}}>*</span>
                </div>
                <div className="radioButtonDiv" style={{zIndex:"10",position:"relative"}}>
                  <span onClick={() => usersToInvite === 0 && this.inviteToggleButton(0)}>
                    <RadioButton
                      id="all-users"
                      handler={this.inviteToggleButton}
                      value={0}
                      isChecked={usersToInvite === 0}
                      label={t("All Users")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                      disabled={usersToInvite != 0}
                    />
                  </span>
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && departmentDetails?.length > 0 &&( <span onClick={() => usersToInvite === 1 && this.inviteToggleButton(1)}>
                    <RadioButton
                      id="departments"
                      handler={this.inviteToggleButton}
                      value={1}
                      isChecked={usersToInvite === 1}
                      label={t("Departments")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                      disabled={usersToInvite !== 1}
                    />
                  </span>)}
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && locationDetails?.length > 0 &&(<span onClick={() => usersToInvite === 2 && this.inviteToggleButton(2)}>
                    <RadioButton
                      id="locations"
                      handler={this.inviteToggleButton}
                      value={2}
                      isChecked={usersToInvite === 2}
                      label={t("Locations")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                      disabled={usersToInvite !== 2}
                    />
                  </span>)}
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && allTeams.length > 0 &&(<span onClick={() => usersToInvite === 3 &&  this.inviteToggleButton(3)}>
                    <RadioButton
                      id="teams"
                      handler={this.inviteToggleButton}
                      value={3}
                      isChecked={usersToInvite === 3}
                      label={t("Teams")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                      disabled={usersToInvite !== 3}
                    />
                  </span>)}
                </div>

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'10px 0px 0px 0px',display:"flex"}}/>
                {(role === "ADMIN" &&
                  locationDetails &&
                  locationDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 && usersToInvite === 2) ||
                (locationDetails &&
                  locationDetails?.length > 0 &&
                  role != "ADMIN" && usersToInvite === 2) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Company Location To Invite")}
                      <span className="astric">*</span>
                    </div>
                    {/* <div className="selectAllRadio">
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton 
                          style={{color:"#005c87",border:"1px solid #669db7"}}
                          background={"#85c0ea"}
                        >
                          {" "}
                          {locationDetails &&
                            com_location &&
                            com_location.length === locationDetails.length && (<div></div>)}{" "}
                        </CustomRadioButton>{" "}
                        {locationDetails &&
                        com_location &&
                        com_location.length === locationDetails.length
                          ? this.props.t("Deselect All")
                          : this.props.t("Select All")}
                      </span>
                    </div> */}
                  </Heading>
                  ) : null}

                {(role === "ADMIN" &&
                  locationDetails &&
                  locationDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1  && usersToInvite === 2) ||
                (locationDetails &&
                  locationDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 2) ? (<Locationwrapper  isWidth={"25%"}  background="white" style={{zIndex:"10",position:"relative"}}>
                    <div className="checkBoxWidth" >
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          onClick={(e) =>
                            selectedLocation.length != locationDetails?.length && this.selectAllLocation(e, locationDetails)
                          }
                          style={{color:"#005c87",border:"1px solid #669db7"}}
                          background={"#85c0ea"}
                          disabled={selectedLocation.length === locationDetails?.length}
                        >
                          {" "}
                          {locationDetails &&
                            com_location.length === locationDetails?.length && (<div></div>)}{" "}
                        </CustomRadioButton>{" "}
                        {this.props.t("All Locations")}
                        {/* {locationDetails &&
                        com_location.length === locationDetails.length
                          ? this.props.t("Deselect All")
                          : this.props.t("Select All")} */}
                      </span>
                    </div>
                    {locationDetails && locationDetails?.length > 0
                      ? locationDetails.map((loc, index) => (
                        <div className="checkBoxWidth" key={index}>
                          <span style={{color:"#005c87"}}>
                            <CustomRadioButton
                              onClick={selectedLocation &&selectedLocation.includes(loc.id) &&com_location &&com_location.includes(loc.id)
                                ? null: () => this.onChangeLocation(loc.id)}
                              background="#afcbd3"
                              disabled={selectedLocation.includes(loc.id)}
                              
                            >
                              {" "}
                              {selectedLocation &&selectedLocation.includes(loc.id) && com_location && com_location.includes(loc.id) ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>) : com_location &&
                                  com_location.includes(loc.id) ? (
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                ) : null}{" "}
                            </CustomRadioButton>
                            {this.removeCommaFromFirstPlace(loc.location)}
                          </span>
                        </div>)): null}
                  </Locationwrapper>) : null}

                {/* Dpartment Listing */}

                {(role === "ADMIN" &&
                  departmentDetails && 
                  departmentDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 1) ||
                (departmentDetails &&
                  departmentDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 1 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select departments")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 1 && departmentDetails &&
                  departmentDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (departmentDetails &&
                  departmentDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 1 ) ? (
                    <Locationwrapper   isWidth={"25%"}  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              presentDepartments.length != departmentDetails?.length && this.selectAllDepartment(e, departmentDetails)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                            disabled={ presentDepartments.length === departmentDetails?.length}
                          >
                            {" "}
                            {departmentDetails &&
                              departments.length === departmentDetails?.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {departmentDetails &&
                          departments.length === departmentDetails?.length
                            ? this.props.t("Deselect All")
                            : this.props.t("All departments")}
                        </span>
                      </div>
                      {departmentDetails && departmentDetails?.length > 0
                        ? departmentDetails.map((dep, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => !presentDepartments.includes(dep.id) && this.onChangeDepartment(dep.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                                disabled={presentDepartments.includes(dep.id)}
                              >
                                {" "}
                                {departments.includes(dep.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(dep.department)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {/* Teams Listing */}

                {(role === "ADMIN" &&
                  allTeams && 
                  allTeams.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 3) ||
                (allTeams &&
                  allTeams.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 3 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select teams")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 3 && allTeams &&
                  allTeams.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (allTeams &&
                  allTeams.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 3 ) ? (
                    <Locationwrapper  isWidth={"25%"}  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              presentTeams.length != allTeams.length && this.selectAllTeam(e, allTeams)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                            disabled={presentTeams.length === allTeams.length}
                          >
                            {" "}
                            {allTeams &&
                              teams.length === allTeams.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {allTeams &&
                          teams.length === allTeams.length
                            ? this.props.t("Deselect All")
                            : this.props.t("All teams")}
                        </span>
                      </div>
                      {allTeams && allTeams.length > 0
                        ? allTeams.map((team, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => !presentTeams.includes(team.id) && this.onChangeTeam(team.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                                disabled={presentTeams.includes(team.id)}
                              >
                                {" "}
                                {teams.includes(team.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(team.name)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {role === "ADMIN" && (<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>)}
                {(companyInfo?.show_spouse|| companyInfo?.show_dependent) && selectedCompanyCheckBox === 1 ?<div>
               
                  {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2)) && companies?.length > 0 && selectedCompanyCheckBox === 1  ? (                 
                    <Heading>
                      <div className="name" style={{color:"#005c87"}}>{("Are you also interested in extending an invitation?")}</div>
                    </Heading>) : null}

                  {role === "ADMIN" && selectedCompanyCheckBox === 1 ? (
                    <Locationwrapper  background="white">
                      {companyInfo?.show_spouse ? <div className="checkBoxWidth">
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={ ()=>{
                              !eventDetail.spouse &&  this.selectSpouseDependent("isSpouse")
                            }
                            }
                            background="#afcbd3"
                            disabled={eventDetail.spouse}
                            
                          >
                            {" "}
                            {eventDetail.spouse ? (
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            ) : isSpouse ? (
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            ) : null}{" "}
                          </CustomRadioButton>
                          {"Spouse"}
                        </span>
                      </div>:null}
                      {companyInfo?.show_dependent ? <div className="checkBoxWidth">
                        <span>
                          <CustomRadioButton
                            onClick={()=>{
                              !eventDetail.dependent && this.selectSpouseDependent("isDependent")
                            }
                            }
                            disabled={eventDetail.dependent}
                          >
                            {" "}
                            {eventDetail.dependent ? (
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            ) : isDependent ? (
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            ) : null}{" "}
                          </CustomRadioButton>
                          {"Dependent"}
                        </span>
                      </div>:null}
                      {locationDetails && locationDetails?.length === 0 && (
                        <div className="checkBoxWidth">
                          <span>
                            <CustomRadioButton
                              onClick={
                                false || !eventDetail.is_spouse_or_dependent
                                  ? ""
                                  : () => this.selectSpouseDependent("isEmployee")
                              }
                            >
                              {" "}
                              {false || !eventDetail.is_spouse_or_dependent ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : isEmployee ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : null}{" "}
                            </CustomRadioButton>
                            {"Employee"}
                          </span>{" "}
                        </div>
                      )}
                    </Locationwrapper>
                  ) : (
                    role != "ADMIN" && (
                      <Locationwrapper  background="white" isWidth={"16.33%"}>
                        {companyInfo?.show_spouse ? <div className="checkBoxWidth">
                          <span style={{color:"#005c87"}}>
                            <CustomRadioButton
                              onClick={ ()=>{
                                !eventDetail.spouse &&  this.selectSpouseDependent("isSpouse")
                              }
                              }
                              background="#afcbd3"
                              disabled={eventDetail.spouse}
                              
                            >
                              {" "}
                              {eventDetail.spouse ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : isSpouse ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : null}{" "}
                            </CustomRadioButton>
                            {this.props.t("Spouse")}
                          </span>
                        </div> : null}
                        {companyInfo?.show_dependent ? <div className="checkBoxWidth">
                          <span>
                            <CustomRadioButton
                              onClick={()=>{
                                !eventDetail.dependent && this.selectSpouseDependent("isDependent")
                              }
                              }
                              disabled={eventDetail.dependent}
                            >
                              {" "}
                              {eventDetail.dependent ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : isDependent ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : null}{" "}
                            </CustomRadioButton>
                            {this.props.t("Dependent")}
                          </span>
                        </div>: null}
                        {/* {locationDetails && locationDetails?.length === 0 && (
                        <div className="checkBoxWidth">
                          <span>
                            <CustomRadioButton
                              onClick={
                                false || !eventDetail.is_spouse_or_dependent
                                  ? ""
                                  : () =>
                                    this.selectSpouseDependent("isEmployee")
                              }
                            >
                              {" "}
                              {false || !eventDetail.is_spouse_or_dependent ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : isEmployee ? (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              ) : null}{" "}
                            </CustomRadioButton>
                            {this.props.t("Employee")}
                          </span>{" "}
                        </div>
                      )} */}
                      </Locationwrapper>
                    )
                  )}</div>:null}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  toggleFlayer = (e) => {
    this.setState({ selectFlayer: e });
  };

  stepFourForm = () => {
    const {
      step,
      date,
      endDate,
      // time,
      // endTime,
      // openDateTimePicker,
      emailOption,
      time_zone,
      recurringEvent,
      eventLimitValue,
      // apiChecker,
      // menuIsOpen,
      showDateTimeInput,
      companyInfo,
      companies,
      selectedCompanyCheckBox
    } = this.state;

    let emailOptionKeys = Object.keys(emailOption);

    // const yesterday = Datetime.moment().subtract(23, "hour");
    // const valid = (currentDate) =>
    //   showDateTimeInput === "date"
    //     ? currentDate.isAfter(Datetime.moment())
    //     : currentDate.isAfter(yesterday);
    const eventWeeklyLimits = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const eventLimits = eventWeeklyLimits;
    // const disabledFunctions = apiChecker;
    const customStyles = {
      control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "hsl(0,0%,70%)" : "hsl(0,0%,70%)",
        },
      }),
    };
    let timeZoneValue;
    timezones["timezones"].map((zone) => {
      if (zone.value === time_zone) {
        timeZoneValue = zone.label;
      }
    });
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="event" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("When would you like to launch your event?")}</div>
              </div>
              <div className="formBody">
                <TitleContainer fullWidth margin="20px 0 20px 0" padding="0px">
                  <FieldTitle style={{color:"#005c87"}}>
                    {this.props.t("Timezone")}
                    {<span>*</span>}
                  </FieldTitle>
                  <SearchDropDown
                    // transform={menuIsOpen ? "rotate(90deg)" : "0"}
                    pcolor={"#005c87"}
                    pfont="rubik-medium"
                    border={"1px solid #afcbd3"}
                    background={"white"}
                    color="#005c87"
                  >
                    <Select
                      defaultValue={timeZoneValue ? timeZoneValue : time_zone}
                      options={timezones["timezones"]}
                      onChange={(option) =>
                        this.onSelectCategory("time_zone", option.value)
                      }
                      classNamePrefix={"menu-item"}
                      styles={customStyles}
                      components={{ DropdownIndicator: this.DropdownIndicator }}
                      onMenuOpen={this.toggleMenu}
                      onMenuClose={this.toggleMenu}
                      placeholder={timeZoneValue ? timeZoneValue : time_zone}
                    />
                  </SearchDropDown>
                </TitleContainer>
                <StyledLabelV2 justify>
                  <div className="name" style={{color:"#005c87"}}>{this.props.t("Event Date and Time")}{<span>*</span>}</div>
                  <div className="radio" style={{ color: "#005c87",width:"auto" }}>
                    <CustomRadioButton
                      // onClick={() => this.changeDateTimeView(showDateTimeInput ? "" : "date")}
                      style={{color:"#005c87",border:"1px solid #669db7", opacity: "0.5", pointerEvents: "none"}}
                      background={"#85c0ea"}
                    >
                      {" "}
                      {showDateTimeInput === "date" && <div></div>}
                    </CustomRadioButton>
                    {this.props.t("All Day")}
                  </div>
                </StyledLabelV2>
                {showDateTimeInput === "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper>
                        <DateTimePicker
                          label="Select start date & time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"100%",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{this.onChangeDate(value?.$d, "date")}}
                          value={date}
                          disablePast={true}
                          // minDate={dayjs().add(1, 'day')}
                          // disabled={launch === 0}
                          slots={{openPickerIcon:CalendarIcon}}
                          
                        />
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  
                    {/* <InputContainerV2 width={"47.5%"}>
                    <div
                      ref={(node) =>
                        openDateTimePicker === "date" &&
                        this.setDatePickerWrapper(node)
                      }
                    >
                      <StyledDateTime
                        open={openDateTimePicker === "date"}
                        inputProps={{
                          placeholder: moment().format("MM/DD/YYYY"),
                          readOnly: true,
                        }}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={date}
                        onChange={(e) => this.changeDate(e, "date")}
                        isValidDate={valid}
                        bgColor={"white !important"}
                        border={"1px solid #afcbd3 !important"}
                        color={"#005c87 !important"}
                      />
                      <DateIconContainer
                        onClick={() => this.showDatePicker("date")}
                      >
                        <img src="/public/images/calendar.svg" alt="calender" />
                      </DateIconContainer>
                    </div>
                  </InputContainerV2> */}
                  </React.Fragment>
                )}
                {showDateTimeInput != "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper>
                        <DateTimePicker
                          label="Select start date & time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"416px",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{this.onChangeDate(value?.$d, "date")}}
                          value={date}
                          disablePast={true}
                          maxDateTime={endDate}
                          slots={{openPickerIcon:CalendarIcon}}
                          // slots={{
                          //   openPickerIcon: }}
                          // openPickerButton={}
                          // disabled={launch === 0}
                          
                        />
                        <DashIconV1>{DashIcon()}</DashIconV1>
                        {recurringEvent === recurringTypes[0] ? (<DateTimePicker
                          label="Select end date & time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"416px"}}
                          onChange={(value)=>{this.onChangeDate(value?.$d, "endDate")}}
                          value={endDate}
                          disablePast={true}
                          minDateTime={date}
                          slots={{openPickerIcon:CalendarIcon}}
                        />):(
                          <TimePicker
                            label="Select End Time"
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            sx={{width:"416px"}}
                            onChange={(value)=>{this.onChangeDate(value?.$d, "endDate")}}
                            disablePast={true}
                            slots={{openPickerIcon:CalendarIcon}}
                          />
                        )}
                      </MUICalendarWrapper>
                    </LocalizationProvider>

                    {/* <div className="dateCard">
                    <InputContainerV2 width={"47.5%"}>
                      <div
                        style={{ width: "100%" }}
                        ref={(node) =>
                          openDateTimePicker === "date" &&
                          this.setDatePickerWrapper(node)
                        }
                        onClick={() => {this.showDatePicker("date");}}
                      >
                        <StyledDateTime
                          open={openDateTimePicker === "date"}
                          inputProps={{
                            placeholder: moment().format("MM/DD/YYYY"),
                            readOnly: true,
                          }}
                          dateFormat="MM/DD/YYYY"
                          closeOnSelect={true}
                          closeOnTab={true}
                          timeFormat={false}
                          value={date}
                          onChange={(e) => this.changeDate(e, "date")}
                          isValidDate={valid}
                          bgColor={"white !important"}
                          border={"1px solid #afcbd3 !important"}
                          color={"#005c87 !important"}
                        />
                        <DateIconContainer
                          onClick={() => this.showDatePicker("date")}
                        >
                          <img
                            src="/public/images/calendar.svg"
                            alt="calender"
                          />
                        </DateIconContainer>
                      </div>
                    </InputContainerV2>

                    <div className="separate">-</div>

                    <InputContainerV2 width={"47.5%"}>
                      <div
                        style={{ width: "100%" }}
                        ref={(node) =>
                          openDateTimePicker === "time" &&
                          this.setDatePickerWrapper(node)
                        }
                        onClick={() => this.showDatePicker("time")}
                      >
                        <StyledDateTime
                          open={openDateTimePicker === "time"}
                          inputProps={{
                            placeholder: moment().format("hh:mm a"),
                            readOnly: true,
                          }}
                          dateFormat={false}
                          closeOnTab={true}
                          value={time}
                          onChange={(e) => this.changeDate(e, "time")}
                          timeInput
                          bgColor={"white !important"}
                          border={"1px solid #afcbd3 !important"}
                          color={"#005c87 !important"}
                        />
                        <DateIconContainer
                          onClick={() => this.showDatePicker("time")}
                        >
                          <StyledSvg
                            height="24px"
                            width="24px"
                            show="#005c87"
                            activeValue="#005c87"
                          >
                            <svg
                              width="24px"
                              height="24px"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11 0C4.95 0 0 4.95 0 11C0 17.05 4.95 22 11 22C17.05 22 22 17.05 22 11C22 4.95 17.05 0 11 0ZM14.6667 12.8333H11C9.9 12.8333 9.16667 12.1 9.16667 11V5.5C9.16667 4.4 9.9 3.66667 11 3.66667C12.1 3.66667 12.8333 4.4 12.8333 5.5V9.16666H14.6667C15.7667 9.16666 16.5 9.9 16.5 11C16.5 12.1 15.7667 12.8333 14.6667 12.8333Z"
                                fill="#005c87"
                              />
                            </svg>
                          </StyledSvg>
                        </DateIconContainer>
                      </div>
                    </InputContainerV2>
                  </div> */}
                  </React.Fragment>
                )}
                {/* {showDateTimeInput != "date" && (
                  <div className="dateCard">
                    {recurringEvent === recurringTypes[0] && (
                      <InputContainerV2 width={"47.5%"}>
                        <div
                          style={{ width: "100%" }}
                          ref={(node) =>
                            openDateTimePicker === "endDate" &&
                            this.setDatePickerWrapper(node)
                          }
                          onClick={() => this.showDatePicker("endDate")}
                        >
                          <StyledDateTime
                            open={openDateTimePicker === "endDate"}
                            inputProps={{
                              placeholder: moment().format("MM/DD/YYYY"),
                              readOnly: true,
                            }}
                            dateFormat="MM/DD/YYYY"
                            closeOnSelect={true}
                            closeOnTab={true}
                            timeFormat={false}
                            value={endDate}
                            onChange={(e) => this.changeDate(e, "endDate")}
                            isValidDate={valid}
                            bgColor={"white !important"}
                            border={"1px solid #afcbd3 !important"}
                            color={"#005c87 !important"}
                          />
                          <DateIconContainer
                            onClick={() => this.showDatePicker("endDate")}
                          >
                            <img
                              src="/public/images/calendar.svg"
                              alt="calender"
                            />
                          </DateIconContainer>
                        </div>
                      </InputContainerV2>
                    )}
                    {recurringEvent === recurringTypes[0] && (
                      <div className="separate">-</div>
                    )}

                    <InputContainerV2 width={"47.5%"}>
                      <div
                        style={{ width: "100%" }}
                        ref={(node) =>
                          openDateTimePicker === "endTime" &&
                          this.setDatePickerWrapper(node)
                        }
                        onClick={() => this.showDatePicker("endTime")}
                      >
                        <StyledDateTime
                          open={openDateTimePicker === "endTime"}
                          inputProps={{
                            placeholder: moment().format("hh:mm a"),
                            readOnly: true,
                          }}
                          dateFormat={false}
                          closeOnTab={true}
                          value={endTime}
                          onChange={(e) => this.changeDate(e, "endTime")}
                          timeInput
                          bgColor={"white !important"}
                          border={"1px solid #afcbd3 !important"}
                          color={"#005c87 !important"}
                        />
                        <DateIconContainer
                          onClick={() => this.showDatePicker("endTime")}
                        >
                          <StyledSvg
                            height="24px"
                            width="24px"
                            show="#005c87"
                            activeValue="#005c87"
                          >
                            <svg
                              width="24px"
                              height="24px"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11 0C4.95 0 0 4.95 0 11C0 17.05 4.95 22 11 22C17.05 22 22 17.05 22 11C22 4.95 17.05 0 11 0ZM14.6667 12.8333H11C9.9 12.8333 9.16667 12.1 9.16667 11V5.5C9.16667 4.4 9.9 3.66667 11 3.66667C12.1 3.66667 12.8333 4.4 12.8333 5.5V9.16666H14.6667C15.7667 9.16666 16.5 9.9 16.5 11C16.5 12.1 15.7667 12.8333 14.6667 12.8333Z"
                                fill="#005c87"
                              />
                            </svg>
                          </StyledSvg>
                        </DateIconContainer>
                      </div>
                    </InputContainerV2>
                  </div>
                )} */}

                {this.state.showFrequency && <><StyledLabel  margin={"20px"} color="#005c87">{this.props.t("Set frequency")}{<span>*</span>}</StyledLabel>
                  <div className="radioButtonDiv">
                    <span
                      onClick={() =>
                        this.onChangeTimeZone("recurringEvent", "does not repeat")
                      }
                    >
                      <RadioButton
                        id="1"
                        handler={() =>
                          this.onChangeTimeZone(
                            "recurringEvent",
                            "does not repeat"
                          )
                        }
                        value={0}
                        isChecked={recurringEvent == "does not repeat"}
                        label={"Do not repeat"}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span
                      onClick={() =>
                        this.onChangeTimeZone("recurringEvent", "weekly")
                      }
                    >
                      <RadioButton
                        id="2"
                        handler={() =>
                          this.onChangeTimeZone("recurringEvent", "weekly")
                        }
                        value={1}
                        isChecked={recurringEvent == "weekly"}
                        label={this.props.t("Weekly")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div></>}
                {recurringEvent !== recurringTypes[0] && (
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", margin: "0 0 0px 0", padding: "15px 0 0 0" }}
                    dropdownOpen={this.state.so}
                    dropdownStyle={{ top: "30px" }}
                    labelText={""}
                    activityDropdownStyle={{ margin: "0 0 0px 0" }}
                    placeholder="Select Option"
                    title={
                      eventLimitValue
                        ? `${eventLimitValue} ${"Weeks"}`
                        : this.props.t("Select Weeks")
                    }
                    id="dropdown-recurring"
                    onClick={() => this.setState({ so: !this.state.so })}
                    data={eventLimits}
                    onSelect={this.onSelectCategory}
                    onSelectParameter={["eventLimitValue", "item"]}
                    active={eventLimitValue}
                    itemValue={false}
                    valueString={"Weeks"}
                    valueIndex={1}
                  />
                )}

                {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2)) && companies?.length > 0 && selectedCompanyCheckBox === 1 && <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>}
                <StyledLabel color="#005c87">
                  {this.props.t("Select your email notifications for this event")}
                </StyledLabel>
                <Locationwrapper
                  border
                  background="none"
                  marginTop="0"
                  padding="0"
                >
                  {emailOptionKeys.map((item, index) => (
                    <div className="checkBoxWidth" key={index}>
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          checked={emailOption[item] === 1}
                          onClick={() => this.onChangeEmail(item)}
                          
                        >
                          {emailOption[item] === 1 && (
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}
                        </CustomRadioButton>
                        {this.props.t("Event")}
                        {item == "launch_event_email"
                          ? this.props.t(" Creation")
                          : item == "event_reminder_email"
                            ? this.props.t(" Reminder")
                            : this.props.t(" Completion")}
                      </span>
                    </div>
                  ))}
                </Locationwrapper>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
  };

  render() {
    const {
      title,
      description,
      imgSrc,
      date,
      companies,
      limitUser,
      time_zone,
      companiesPermission,
      locationPermission,
      step,
      com_location,
      addLimitCheckbox,
      eventLink,
      usersToInvite,
      teams,
      departments
    } = this.state;
    const { isLoading, allCompanies, userCompany, role, /*locationDetails*/ } =
      this.props;
    if (
      (companiesPermission && !allCompanies) ||
      (locationPermission && !userCompany) ||
      isLoading
    ) {
      return <Waiting />;
    }
    return (
      <React.Fragment>
        {step === 1
          ? this.stepOneForm()
          : step === 2
            ? this.stepTwoForm()
            : step === 3
              ? this.stepThreeForm()
              : this.stepFourForm()}
        {step === 1 && (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                disabled={!title ||!imgSrc ||!description ||!limitUser||(addLimitCheckbox&&limitUser===0) ||(!location&&!eventLink)}
                title={this.props.t("Next")}
                styles={{marginAuto:"auto",color:"#007AB1" }}
                nextIcon={NextArrows("white")}
                // style={{boxShadow: (!title ||!imgSrc ||!description ||!limitUser||(addLimitCheckbox&&limitUser===0) ||(!location&&!eventLink)) ? "none" : "0px 8px 24px 0px #005C8780"}}
              />
            </Main>
          </ButtonContainer>
        )}
        {step === 2 && (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={!time_zone||!date || (!this.state.selectFlayer && !this.state.flayerName)}
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
                // style={{boxShadow:(!time_zone||!date) ? "none" : "0px 8px 24px 0px #005C8780"}}
              />
            </div>
          </ButtonContainer>
        )}
        {step === 3 && (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={ !companies.length > 0 && role === "ADMIN"|| companies.length === 0 || (usersToInvite === 2 && !com_location.length > 0) || (usersToInvite === 1 && !departments.length > 0) || (usersToInvite === 3 && !teams.length > 0)}
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(4)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
                // style={{boxShadow:((!com_location.length>0&&!companies.length>0&&role === "ADMIN")||role !== "ADMIN"&&!com_location.length>0&&!_.isEmpty(locationDetails)) ? "none" : "0px 8px 24px 0px #005C87"}}
              />
            </div>
          </ButtonContainer>
        )}
        {step === 4 && (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"#007AB1", marginAuto:'auto'}}
                onClick={this.saveEvent}
                title={this.props.t("Save Changes")}
                // style={{boxShadow: "0px 8px 24px 0px #005C8780"}}
              />
            </div>
          </ButtonContainer>
        )}
      </React.Fragment>
    );
  }
}

EditEvent.propTypes = {
  createEvent: PropTypes.func.isRequired,
  getPlacesAction: PropTypes.func.isRequired,
  places: PropTypes.array,
  isLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  fetchAllCompanies: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  eventDetail: PropTypes.object,
  EditEventAction: PropTypes.func,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  userPermissions: PropTypes.array,
  getCompanyLocation: PropTypes.func,
  t: PropTypes.func,
  getCompanyDepartment:PropTypes.func,
  departmentDetails:PropTypes.array,
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  eventDetail: state.events.eventDetail,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  departmentDetails: state.register.departmentDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  EditEventAction: (data) => dispatch(EditEventAction(data)),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
  getCompanyDepartment: (id) => dispatch(getCompanyDepartment(id))
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(EditEvent));